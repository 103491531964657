export function supplementObject<T extends {}>(obj: T, wth: T): T {
  let field: string;
  for (field in { ...obj, ...wth}) {
    if (obj[field] instanceof Array || !(obj[field] instanceof Object)) {
      obj[field] = obj[field] != undefined ? obj[field] : wth[field];
      continue;
    }
    obj[field] = {...(wth[field] || {}), ...(obj[field] || {})}
  }

  return obj;
}

export function supplementObjectSoft<T extends {}>(obj: T, wth: T): T {
  let field: string;
  for (field in { ...obj, ...wth}) {
    if (obj[field] !== null) obj[field] = obj[field] != undefined ? obj[field] : wth[field];
  }
  return obj;
}