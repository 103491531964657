import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { IStorageService } from "../interfaces/storage-service.interface";

export class GlobalStorageProvider implements IStorageProvider {
	constructor(private readonly storageServices: IStorageService[]) {}

  public addItem<T = any>(key: string, value: T, expiryDate: Date) {
    for (let storageService of this.storageServices.sort((s1, s2) => s1.priority - s2.priority)) {
      if (storageService.isSupported()) {
        storageService.setItem(key, value, expiryDate);
        break;
      }
    }
  }

  public getItem<T = any>(key: string): T | undefined {
    let value: any = undefined;
    for (let storageService of this.storageServices.sort((s1, s2) => s1.priority - s2.priority)) {
      if (storageService.isSupported()) {
        value = storageService.getItem(key);
        if (value) break;
      }
    }

    return value;
  }
}