import { QuizEvent } from "./enums/quiz-event.enum";
import { QuizStatus } from "./enums/quiz-status.enum";
import { IIframe } from "./interfaces/iframe.interface";
import { IQuiz } from "./interfaces/quiz.interface";
import { IStorageProvider } from "./interfaces/storage-provider.interface";
import { getQueryParams } from "./utils/get-query-params";
import { EventEmitter } from "./components/event-emitter";
import { QuizState } from "./quiz-state";
import { IStoreProvider } from './interfaces/store-provider.interface';
import { QuizMode } from './enums/quiz-mode.enum';
import { OpenTrigger } from './enums/open-trigger.enum';
import { getCookie } from "./utils/get-cookie";
import { IPostMessage } from './interfaces/post-message.interface';
import { PostMessageEvent } from "./enums/post-message-event.enum";
import { IOptionsSchema } from './dto/options.schema';
import { ILead } from "./interfaces/lead.interface";
import { IApiProvider } from './interfaces/api-provider.interface';
import { SceneToken } from "./enums/scene-token.enum";
import { IModule } from "./interfaces/module.interface";
import { IPostMessageProvider } from "./interfaces/post-message-provider.interface";
import { IDebugProvider } from "./interfaces/debug-provider.interface";
import { ReachGoalData } from "./modules/analytics/interfaces/reach-goal-data.interface";

export class Quiz implements IQuiz {
  constructor(
    private readonly options: IOptionsSchema,
    private readonly storeProvider: IStoreProvider<QuizState>,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>,
    public readonly postMessageProvider: IPostMessageProvider,
    private readonly storageProvider: IStorageProvider,
    private readonly iframe: IIframe,
    private readonly apiProvider: IApiProvider,
    private readonly debugProvider: IDebugProvider
  ) {}

  private beforeOpenActions: Array<(trigger: OpenTrigger, page?: SceneToken) => boolean> = [];

  public async init(): Promise<void> {
    this.storeProvider.updateState({
      status: QuizStatus.INITIALIZING,
      quizOptions: this.options
    });

    this.apiProvider.isQuizAvailable(this.options.quizId).then(available => {
      if (!available) {
        this.quizEventEmitter.emit(QuizEvent.ABORT);
      }
    }).catch(e => console.error(e));
    
    this.quizEventEmitter.subscribe(QuizEvent.ABORT, () => {
      if (this.options.mode == QuizMode.POPUP) this.close();
      this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
      console.error(`Quiz ${this.options.quizId} is not available`);
    });

    this.quizEventEmitter.emit(QuizEvent.BEFORE_INIT);
    this.storeProvider.updateState({ leadExtraParams: this.getLeadExtraParams() });

    this.postMessageProvider.subscribeAll(message => {
      this.quizEventEmitter.emit(QuizEvent.RECEIVE_POST_MESSAGE, message);
      this.debugProvider.log("postMessage: ", message);

      switch(message.event) {
        case PostMessageEvent.LOAD: 
          this.storeProvider.updateState({ wasLoaded: true });
          break;
        
        case PostMessageEvent.GET_LEAD:
          this.storeProvider.updateState({ leadReceived: true });
          this.quizEventEmitter.emit<ILead>(QuizEvent.GET_LEAD, message.data as ILead);
          break;
        
        case PostMessageEvent.REACH_GOAL:
          this.quizEventEmitter.emit<ReachGoalData>(QuizEvent.REACH_GOAL, message.data as ReachGoalData);
          break;
      }
    });

    await this.iframe.init();
    this.iframe.loadContent();
    this.sendDataToIframe(PostMessageEvent.SET_PLUGIN_DATA, {
      quizId: this.options.quizId,
      mode: this.options.mode,
      domain: window.location.hostname,
      url: window.location.href,
      distributorAnswers: this.options.answerFilter,
      questionFilters: this.options.questionFilters,
      customContent: this.options.customContent,
      customSettings: this.options.customSettings,
      leadExtraParams: this.storeProvider.getState().leadExtraParams
    });

    if (this.options.mode == QuizMode.CUSTOM) this.open(OpenTrigger.QUIZ_MODE_CUSTOM);

    if (this.options.mode == QuizMode.FULL) {
      this.open(OpenTrigger.QUIZ_MODE_FULL);
      this.addMeta();
    }

    const popupTimeout = this.storageProvider.getItem("popup");

    if (this.options.mode == QuizMode.POPUP && this.options.autoOpen && !popupTimeout) {
      this.autoOpen(this.options.autoOpen);
    }

    this.postMessageProvider.subscribe<IPostMessage>(PostMessageEvent.CLICK_CROSS, () => {
      this.quizEventEmitter.emit(QuizEvent.CLICK_CROSS);
      if (this.options.mode == QuizMode.POPUP) this.close();
    });

    if (this.options.openOnScroll && !popupTimeout) {
      window.addEventListener("scroll", () => this.onScroll());
    }

    if (this.options.openOnLeave && !popupTimeout) {
      document.addEventListener("mouseleave", (e) => this.onLeave(e));
    }

    this.quizEventEmitter.emit(QuizEvent.INIT);
    this.storeProvider.updateState({ initialized: true, status: QuizStatus.CLOSED });
    this.debugProvider.log("quiz event: ", QuizEvent.INIT);
  }

  private getLeadExtraParams() {
    return {
      ...getQueryParams(window.location.search.substring(1)),
      ...this.options.leadExtraParams,
      href: window.location.href,
      domain: window.location.hostname,
      cookies: {
        _ga: getCookie("_ga"),
        _fbp: getCookie("_fbp"),
        _ym_uid: getCookie("_ym_uid"),
        roistat_visit: getCookie("roistat_visit"),
        roistat_first_visit: getCookie("roistat_first_visit"),
        roistat_visit_cookie_expire: getCookie("roistat_visit_cookie_expire"),
        _ct_session_id: getCookie("_ct_session_id")
      }
    }
  }

  public async sendDataToIframe(event: PostMessageEvent, data: Record<string, any>) {
    this.postMessageProvider.send(event, data);
  }

  public beforeOpen(callback: (trigger: OpenTrigger, page?: SceneToken) => boolean) {
    this.beforeOpenActions.push(callback);
  }

  public getState() {
    return this.storeProvider.getState();
  }

  public initialized() {
    return this.storeProvider.getState().initialized;
  }

  public async open(trigger: OpenTrigger = OpenTrigger.OTHER, page?: SceneToken) {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) return;
    
    if (!this.beforeOpenActions.every(canMoveOn => canMoveOn(trigger, page))) return;
    
    // Если плагин в процессе инициализации, то ожидаем
    if (this.storeProvider.getState().status == QuizStatus.INITIALIZING) {
      const isInitialized = await Promise.any([
        new Promise<boolean>(resolve => this.quizEventEmitter.subscribe(QuizEvent.ABORT, () => resolve(false))),
        new Promise<boolean>(resolve => this.quizEventEmitter.subscribe(QuizEvent.INIT, () => resolve(true)))
      ]);
      if (!isInitialized) return;
    }

    if (!this.storeProvider.getState().initialized) throw new Error("Необходима инициализация");

    this.iframe.show();
    const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60 * this.options.autoOpenLimit);
    this.storageProvider.addItem("popup", true, expiryDate);

    await this.postMessageProvider.send(PostMessageEvent.OPEN, { trigger, page });

    this.storeProvider.updateState({
      status: QuizStatus.OPENED,
      wasOpened: true,
    });
    this.quizEventEmitter.emit(QuizEvent.OPEN);
  }

  public close() {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) {
      this.storeProvider.updateState({
        status: QuizStatus.CLOSED,
        wasClosed: true,
      });
      this.iframe.hide();
      this.quizEventEmitter.emit(QuizEvent.CLOSE);
    }
  }

  public bind<T>(event: QuizEvent, callback: (e: T) => void) {
    return this.quizEventEmitter.subscribe(event, callback);
  }

  public unbind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.unsubscribe(event, callback);
  }

  public emitEvent<T>(event: QuizEvent, params: T) {
    this.quizEventEmitter.emit(event, params);
  }

  public setAutoOpen(seconds?: number) {
    const timer = seconds || this.options.autoOpen;
    if (timer) this.autoOpen(timer);
  }

  public sendLeadExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send(PostMessageEvent.SEND_LEAD_EXTRA_PARAMS, params);
  }

  public sendExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send(PostMessageEvent.SEND_EXTRA_PARAMS, params);
  }

  public setHeaderPhone(phone: string) {
    return this.postMessageProvider.send(PostMessageEvent.SET_HEADER_PHONE, { phone });
  }

  private autoOpen(seconds: number) {
    const { autoOpenTimeoutId } = this.storeProvider.getState();
    if (autoOpenTimeoutId) clearTimeout(autoOpenTimeoutId);

    const timeoutId = setTimeout(() => {
      const { wasOpened, status } = this.storeProvider.getState();
      if (!wasOpened && status == QuizStatus.CLOSED) {
        this.open(OpenTrigger.AUTO_OPEN);
      }
    }, seconds * 1000);

    this.storeProvider.updateState({
      autoOpenTimeoutId: timeoutId
    });
  }

  private onScroll(): void {
    const { pageYOffset, innerHeight } = window;

    if (this.storeProvider.getState().wasOpened) {
      return window.removeEventListener("scroll", this.onScroll);
    }

    if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) {
      this.open(OpenTrigger.SCROLL);
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  private onLeave(event: MouseEvent): void {
    if (this.storeProvider.getState().wasOpened) {
      return document.removeEventListener("mouseleave", this.onLeave);
    }

    if (event.clientY < 0) {
      this.open(OpenTrigger.LEAVE);
      document.removeEventListener("mouseleave", this.onLeave);
    }
  }

  private addMeta() {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }
}