import { IOptionsSchema } from "./dto/options.schema";
import { QuizStatus } from "./enums/quiz-status.enum";

export class QuizState {
  readonly status: QuizStatus = QuizStatus.CLOSED;
  readonly wasOpened: boolean = false;
  readonly wasClosed: boolean = false;
  readonly wasLoaded: boolean = false;
  readonly leadReceived: boolean = false;
  readonly initialized: boolean = false;
  readonly iframeAppended: boolean = false;
  readonly quizOptions: IOptionsSchema;
  readonly leadExtraParams: Record<string, any> = {};
  readonly autoOpenTimeoutId?: NodeJS.Timeout = undefined;
}