import { CookieStorage } from "cookie-storage";
import { IStorageService } from "../interfaces/storage-service.interface";

export class CookieStorageService implements IStorageService {
  constructor (private readonly prefix: string = "dentolo_", public priority: number = 1) {}

  private cookieStorage = new CookieStorage();

  public setItem<T = any>(key: string, value: T, expiryDate: Date) {
    this.cookieStorage.setItem(this.prefix + key, JSON.stringify(value), { expires: expiryDate });
  }

  public getItem<T>(key: string) {
    try {
      const item = JSON.parse(String(this.cookieStorage.getItem(this.prefix + key))) as T;
      return item;
    } catch(e) {
      console.error(e);
      return undefined;
    }
  }

  public isSupported() {
    const enabled = window.navigator.cookieEnabled;
    if (!enabled) console.error("cookies is disabled");
    return enabled;
  }
}