import { color } from "console-log-colors";
import { IDebugProvider } from "../interfaces/debug-provider.interface";
import { IOptions } from "../interfaces/options.interface";

export enum LogLevel {
  INFO="INFO",
  WARNING="WARNING",
  ERROR="ERROR"
}

export class DebugProvider implements IDebugProvider {
  constructor(private active: boolean) {}

  private style: typeof color = color;

  public enable() {
    this.active = true;
  }

  public log(title: string, ...data: any[]) {
    if (!this.active) return;
    let formattedText = this.style.yellowBright(title);

    data?.forEach((val, i) => {
      formattedText = formattedText + (i > 0 ? "\n" : "") + this.formatData(val);
    })

    console.log(this.style.bold.magenta("[DENTOLO DEBUG]") + '\t' + formattedText);
  }

  private formatData(value: any, tab: number = 0, key = false) {
    if (value instanceof Array) {
      const padding = tab == 0 ? "\n" : "";
      return "[" + padding + value.map(val => this.formatData(val, 1)).join(", ") + padding + "]";
    }

    if (typeof value == "object" && value !== null) {
      return (!key ? "\t".repeat(tab) : "") + "{\n" + 
        Object.entries(value).map(([key, val]) => {
          return "\t".repeat(tab + 1) + key + ": " + this.formatData(val, tab + 1, true);
        }).join('\n') + 
      "\n" + "\t".repeat(tab) + "}";
    }

    if (typeof value == "boolean") {
      return this.style.blueBright(value);
    }

    if (typeof value == "number") {
      return this.style.blueBright(value);
    }

    if (typeof value == "string") {
      return this.style.greenBright('"' + value  + '"');
    }

    return this.style.gray(value);
  }

  private formatObject(options: object, tab: number = 0) {
    const optionsArray = Object.entries(options).map(([key, value]) => {
      const formatedKey = "\t".repeat(tab) + key;
      let processedValue = value;
      if (value instanceof Array) {
        return formatedKey + ": " + "[" + value.map(value => this.style.greenBright('"' + value + '"')).join(", ") + "]";
      }

      if (value instanceof Object) {
        return formatedKey + ": " + "{\n" + this.formatObject(value, 1) + "\n}";
      }

      if (typeof value == "boolean") {
        return formatedKey + ": " + this.style.blueBright(processedValue);
      }

      if (typeof value == "number") {
        return formatedKey + ": " + this.style.blueBright(processedValue  );
      }

      if (typeof value == "string") {
        return formatedKey + ": " + this.style.greenBright('"' + processedValue  + '"');
      }

      return formatedKey + ": " + this.style.gray(processedValue);
    })

    return optionsArray.join('\n');
  }
}