import { AppConfig } from "../config/app.config";
import { IHttpProvider } from "../interfaces/http-provider.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { pause } from "../utils/pause";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface FetchOptions {
  method?: HttpMethod;
  body?: Record<string, any>;
  timeout?: number;
}

export class HttpProvider implements IHttpProvider {
  constructor(
    private readonly config: AppConfig, 
    private readonly storageProvider: IStorageProvider
  ) {}

  private get baseUrl() {
    // if (this.config.env == "development") return this.config.api_host;

    const baseUrlFromStorage = this.storageProvider.getItem("univer_api_base_url");
    const productionBaseUrl = baseUrlFromStorage || this.config.api_host;
    this.baseUrl = productionBaseUrl;
    return productionBaseUrl;
  }

  private set baseUrl(value: string) {
    const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60);
    this.storageProvider.addItem("univer_api_base_url", value, expiryDate);
  }

  public async fetch<T = any>(path: string, options: FetchOptions = {}): Promise<T> {
    const response = await this.fetchAnyAvailableServer<T>(path, options);
    return response;
  }
  
  public async fetchAnyAvailableServer<T = any>(path: string, options: FetchOptions = {}): Promise<T> {
    const reserveUrl = this.baseUrl == this.config.api_host ? this.config.reserve_api_host : this.config.api_host;
    const baseUrlList = [this.baseUrl, reserveUrl];
    let error: any;

    for (let url of baseUrlList) {
      try {
        const response = await this.fetchWithTimeout<T>(url + path, options);
        this.baseUrl = url;
        return response;
      } catch (e) {
        error = e;
      }
    }

    throw new Error(error);
  }

  private async fetchWithTimeout<T>(url: string, options: FetchOptions = {}): Promise<T> {
    const { method = "GET", timeout = 5000, body = undefined } = options;
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), timeout);

    try {
      const response = await fetch(url, { 
        method, 
        signal: abortController.signal,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      const data: T = await response.json();
      return data;

    } catch (e) {
      console.error(e);
      throw new Error(e);
    } finally {
      clearTimeout(timeoutId);
    }
  }

  public async get<T>(url: string, timeout?: number): Promise<T> {
    return this.fetch<T>(url, { timeout });
  }

  public async post<T>(url: string, body: object): Promise<T> {
    return this.fetch<T>(url, { method: "POST", body });
  }

  public async put<T>(url: string, body: object): Promise<T> {
    return this.fetch<T>(url, { method: "PUT", body });
  }

  public async patch<T>(url: string, body: object): Promise<T> {
    return this.fetch<T>(url, { method: "PATCH", body });
  }

  public async delete<T>(url: string): Promise<T> {
    return this.fetch<T>(url, { method: "DELETE" });
  }
}