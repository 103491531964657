import { clearObject } from "../utils/clear-object";

export const appConfig = <T extends {} = any>(args: Partial<T> = {}) => ({
    env: (process.env.NODE_ENV || "production") as ("production" | "development"),
    quiz_host: String(process.env.QUIZ_HOST) || "",
    api_host: String(process.env.API_HOST) || "",
    reserve_api_host: String(process.env.RESERVE_API_HOST) || "",
    post_message_access_key: process.env.POST_MESSAGE_ACCESS_KEY || "SLFKsvlkjLJLSasp3yu8pia14RTLDFJ2432sJ",
    ya_metrika_counter: Number(process.env.YA_METRIKA_COUNTER) || 74355388,
    gtag_id: process.env.GTAG_ID || "UA-198791670-1",
    version: process.env.npm_package_version,
    ...clearObject(args)
});

export type AppConfig = ReturnType<typeof appConfig>;