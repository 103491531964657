import { StorageData } from "../interfaces/storage-data.interface";
import { IStorageService } from "../interfaces/storage-service.interface";

export class SessionStorageService implements IStorageService {
  constructor (private readonly prefix: string = "dentolo_", public priority: number = 1) {}

  public setItem<T = any>(key: string, value: T, expiryDate: Date) {
    window.sessionStorage.setItem(this.prefix + key, JSON.stringify({ value, expiryDate }));
  }

  public getItem<T>(key: string) {
    try {
      const item = JSON.parse(String(window.sessionStorage.getItem(this.prefix + key))) as StorageData<T>;
      if (item.value != undefined && item.expiryDate) {
        if (new Date(item.expiryDate).getTime() > new Date().getTime()) return item.value;
      }
      
      window.sessionStorage.removeItem(this.prefix + key);
      return undefined;
    } catch(e) { 
      return undefined 
    };
  }

  public isSupported() {
    try {
			const testKey = "__some_random_key_you_are_not_going_to_use__";
			window.sessionStorage.setItem(testKey, testKey);
			window.sessionStorage.removeItem(testKey);
			return true;
		} catch (e) {
			console.error('sessionStorage is disabled');
			return false;
		}
  }
}