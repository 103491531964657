import { IApiProvider } from '../interfaces/api-provider.interface';
import { IClientSetting } from '../interfaces/client-setting.interface';
import { IVisitSchema } from '../dto/visit.schema';
import { IVisit } from '../interfaces/visit.interface';
import { IHttpProvider } from '../interfaces/http-provider.interface';

export class ApiProvider implements IApiProvider {
  constructor(private readonly httpProvider: IHttpProvider) {}

  public getSettings(projectId: string, path: string, version: number): Promise<IClientSetting[]> {
    return this.httpProvider.get(`/projects/${projectId}/client-settings?path=${path}&version=${version}`);
  }

  public isQuizAvailable(quizId: string): Promise<boolean> {
    return this.httpProvider.get<{ active: boolean }>(`/quizzes/${quizId}/availability`).then(data => {
      return data.active;
    });
  }

  public getLabels(quizId: string): Promise<Record<string, string>> {
    return this.httpProvider.get<Record<string, string>>(`/quizzes/${quizId}/labels`);
  }

  public createVisit(visitDto: IVisitSchema): Promise<IVisit> {
    return this.httpProvider.post<IVisit>(`/visits`, visitDto);
  }

  public updateVisit(id: string, visitDto: Partial<IVisitSchema>): Promise<IVisit> {
    return this.httpProvider.patch<IVisit>(`/visits/${id}`, visitDto);
  }
}