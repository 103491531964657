export enum AnalyticsEvent {
  OPEN = "open",
  CLOSE = "close",
  CLICK_CROSS = "click_cross", //нажали на крестик (закрыли опросник)
  START = "start",
  START_BY_SCROLL = "start_by_scroll",
  START_BY_FLYING_BUTTON = "start_by_flying_btn",
  CHOOSE_DISTRIBUTOR_ANSWER = "choose_distributor_answer",
  REACH_QUESTION = "reach_question",
  PICK_ANSWER = "pick_answer",
  REACH_PHONE_FORM = "reach_phone_form",
  GET_LEAD = "get_lead",
  GET_APPOINTMENT = "get_appointment", //выбрали удобное время для приема
  CLICK_FINAL_PAGE_BUTTON = "click_final_page_button", //по-умолчанию "узнать о нас больше"
  CLICK_TRADEMARK = "click_trademark",
  CLICK_HEADER_PHONE = "click_header_phone",
  CLICK_WHATSAPP_BUTTON = "click_whatsapp_button",
  CLICK_MINOR_BUTTON = "click_minor_button",
  SHOW_FLYING_BTN = "show_flying_btn",
  OPEN_SING_UP = "open_sign_up" //открыли окно записи на прием на первом экране
}