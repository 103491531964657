import { z } from "zod";
import { AnalyticsType } from "../enums/analytics-type.enum";
import { analyticsBindEventDataSchema } from "./analytics-bind-event-data.schema";

export const analyticsDataSchema = z.object({
  type: z.nativeEnum(AnalyticsType),
  id: z.union([z.string(), z.number()]),
  options: z.optional(z.record(z.string(), z.any())),
  prefix: z.optional(z.string()),
  bindEventsList: z.optional(analyticsBindEventDataSchema.array())
})