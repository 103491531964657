import { Subscription } from "./subscription";

export class EventEmitter<E> {
  constructor() {}

  private subscriptions: Subscription<E>[] = [];

  public subscribe<T>(event: E, callback: (data: T) => void): Subscription<E> {
    const subscription = new Subscription(event, callback, this.unsubscribe.bind(this, event, callback));
    this.subscriptions.push(subscription);

    return subscription;
  }

  public unsubscribe<T>(event: E, callback: (data: T) => void): void {
    this.subscriptions = this.subscriptions.filter(subscription => {
      return (subscription.event != event || subscription.callback != callback)
    });
  }

  public emit<T>(event: E, data?: T): void {
    this.subscriptions.filter(subscription => subscription.event == event)
      .forEach(subscription => {
        try {
          subscription.notify(data);
        } catch (e) {
          console.error(`Error in Dentolo quiz callback, event "${event}".`, e);
        }
      });
  }
}