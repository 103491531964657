import { QuizFactory } from './quiz-factory';
import { IQuiz } from "./interfaces/quiz.interface";
import { ApiProvider } from './providers/api.provider';
import { appConfig } from './config/app.config';
import { DebugProvider } from './providers/debug.provider';
import { HttpProvider } from './providers/http.provider';
import { GlobalStorageProvider } from './providers/storage.provider';
import { LocalStorageService } from './providers/local-storage.service';
import { CookieStorageService } from './providers/cookie-storage.service';
import { SessionStorageService } from './providers/session-storage.service';

declare global {
  interface Window {
    quizFactory: QuizFactory;
    dentoloUQuiz: IQuiz;
  }
}

const urlParams = new URLSearchParams(window.location.search);
const debug = Boolean(urlParams.get('_dentolo_debug'));

const storageProvider = new GlobalStorageProvider([
  new LocalStorageService("dentolo_", 1),
  new CookieStorageService("dentolo_", 2),
  new SessionStorageService("dentolo_", 3)
]);
const httpProvider = new HttpProvider(appConfig(), storageProvider);
const debugProvider = new DebugProvider(debug);
const apiProvider = new ApiProvider(httpProvider);
const quizFactory = new QuizFactory(apiProvider, debugProvider, storageProvider);
window.quizFactory = quizFactory;