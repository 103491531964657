import { z } from "zod";
import { FixBlockMode } from '../modules/fix-block/enums/fix-block-mode.enum';
import { MiniWidgetPosition } from "../modules/fix-block/enums/mini-widget-position.enum";
import { SceneToken } from "../enums/scene-token.enum";

const DEFAULT_OFFER_TEXT = "Рассчитайте предварительную цену, пройдя короткий тест за 20 секунд";
const DEFAULT_BUTTON_TEXT = "Рассчитать цену онлайн";
const DEFAULT_IMAGE_SRC = {
  300: "https://cdn.reserve-donkin-leads.ru/img/fix-block_320.png",
  959: "https://cdn.reserve-donkin-leads.ru/img/fix-block_1024.png"
}

export const fixBlockOptionsSchema = z.object({
  mode: z.nativeEnum(FixBlockMode).default(FixBlockMode.FULL),
  initialPageIndex: z.nativeEnum(SceneToken).default(SceneToken.QUESTION),
  offerText: z.string().default(DEFAULT_OFFER_TEXT),
  buttonText: z.string().default(DEFAULT_BUTTON_TEXT),
  imageSrc: z.string().or(z.record(z.string())).default(DEFAULT_IMAGE_SRC),
  hideOnBottom: z.boolean().default(false),
  hideOnQuizOpen: z.boolean().default(false),
  showMiniOnCloseFullWidget: z.boolean().default(true),
  miniWidgetPosition: z.nativeEnum(MiniWidgetPosition).default(MiniWidgetPosition.RIGHT_BOTTOM),
  miniWidgetType: z.enum(["round", "button"]).default("round"),
  appearance: z.optional(z.record(z.string(), z.any())),
  showMiniWidgetFromMobile: z.optional(z.boolean())
});

export type IFixBlockOptionsSchema = z.infer<typeof fixBlockOptionsSchema>;