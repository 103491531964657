export const enum QuizEvent {
  BEFORE_INIT = "before_init",
  ABORT = "abort",
  INIT = "init",
  OPEN = "open",
  CLOSE = "close",
  CLICK_CROSS = "click_cross",
  GET_LEAD = "get_lead",
  REACH_GOAL = "reach_goal",
  LOAD_MODULE = "load_module",
  RECEIVE_POST_MESSAGE = "receive_post_message"
}