import { EventEmitter } from "../components/event-emitter";
import { QuizEvent } from "../enums/quiz-event.enum";
import { IDebugProvider } from "../interfaces/debug-provider.interface";
import { IModule } from "../interfaces/module.interface";
import { IQuiz } from "../interfaces/quiz.interface";

export class ModuleProvider {
  constructor(
    private readonly debugProvider: IDebugProvider,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>
  ) {}

  public readonly modules: ModuleWrapper[] = [];

  public async useModule(moduleName: string, ...args: any) {
    const foundModule = this.modules.find(module => module.name == moduleName);
    if (foundModule) return foundModule;

    const module = new ModuleWrapper(moduleName, args);
    this.modules.push(module);

    module.load().then(() => {
      this.debugProvider.log("load module: ", moduleName);
      this.quizEventEmitter.emit(QuizEvent.LOAD_MODULE, module);
    });

    return module;
  }
}

export class ModuleWrapper implements IModule {
  constructor(public readonly name: string, private args: any[]) {}

  private instance: IModule;
  private isInited: boolean;
  private eventEmitter = new EventEmitter<"load">();

  public getData() {
    this.checkInit();
    return this.instance.getData();
  }

  public async load() {
    const module = await import(`../modules/${this.name}/index.ts`);
    const ModuleConstructor = module.default as { new(options?: any): IModule };
    this.instance = new ModuleConstructor(...this.args);
    this.eventEmitter.emit("load", this.instance);
  }

  public init(quiz: IQuiz) {
    if (this.isInited) return;

    if (this.instance) this.instance.init(quiz);
      else this.eventEmitter.subscribe<IModule>("load", instance => instance.init(quiz))
    this.isInited = true;
  }

  private checkInit() {
    if (!this.isInited) throw `module ${this.name} is not initialized`;
  }
}