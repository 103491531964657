import { AppConfig, appConfig } from "./config/app.config";
import { QuizEvent } from "./enums/quiz-event.enum";
import { EventEmitter } from "./components/event-emitter";
import { IFrame } from "./components/iframe";
import { PostMessageProvider } from "./providers/post-message.provider";
import { Quiz } from "./quiz";
import { QuizState } from "./quiz-state";
import { StoreProvider } from "./providers/store.provider";
import { optionsSchema } from './dto/options.schema';
import { IOptions } from "./interfaces/options.interface";
import { AnalyticsType } from "./modules/analytics/enums/analytics-type.enum";
import { IClientSetting } from './interfaces/client-setting.interface';
import { supplementObjectSoft } from './utils/supplement-object';
import { QuizMode } from "./enums/quiz-mode.enum";
import { IApiProvider } from './interfaces/api-provider.interface';
import { IModule } from "./interfaces/module.interface";
import { IAnalyticsData } from './modules/analytics/interfaces/analytics-data.interface';
import { DebugProvider } from "./providers/debug.provider";
import { ReachGoalData } from "./modules/analytics/interfaces/reach-goal-data.interface";
import { AnalyticsEvent } from "./modules/analytics/enums/analytics-event.enum";
import { IApplySettingsByProjectIdOptions } from "./interfaces/apply-settings-by-project-id-options.interface";
import { GlobalStorageProvider } from "./providers/storage.provider";
import { CookieStorageService } from "./providers/cookie-storage.service";
import { LocalStorageService } from "./providers/local-storage.service";
import { SessionStorageService } from "./providers/session-storage.service";
import { ModuleProvider } from "./providers/module.provider";
import { IStorageProvider } from "./interfaces/storage-provider.interface";

export class QuizFactory {
  constructor(
    private readonly apiProvider: IApiProvider,
    private readonly debugProvider: DebugProvider,
    private readonly storageProvider: IStorageProvider
  ) {
    this.debugProvider.log("plugin version: ", this.VERSION);
    this.quizEventEmitter = new EventEmitter<QuizEvent>();
    this.moduleProvider = new ModuleProvider(this.debugProvider, this.quizEventEmitter);
  }

  private VERSION = 4;
  private quizEventEmitter: EventEmitter<QuizEvent>;
  private moduleProvider: ModuleProvider;

  public create(options: IOptions) {
    const parsedOptions = optionsSchema.parse(options);
    const config = appConfig<AppConfig>({ quiz_host: options.host });

    const storeProvider = new StoreProvider(new QuizState());
    const iframe = new IFrame(config.quiz_host, parsedOptions.mode, options.iframe);
    const postMessageProvider = new PostMessageProvider(iframe, config, storeProvider);

    this.debugProvider.log("quiz provided options: \n", options);
    this.debugProvider.log("quiz parsed options: \n", parsedOptions);
    this.debugProvider.log("quiz host: ", config.quiz_host);
    this.debugProvider.log("api host: ", config.api_host);

    const quiz = new Quiz(
      parsedOptions,
      storeProvider,
      this.quizEventEmitter,
      postMessageProvider,
      this.storageProvider,
      iframe,
      this.apiProvider,
      this.debugProvider
    );

    const analyticsDatas: IAnalyticsData[] = [
      ...(options.analytics || []),
      { type: AnalyticsType.YA_METRIKA, id: config.ya_metrika_counter },
      { type: AnalyticsType.OWN_ANALYTICS, id: options.quizId }
    ];

    this.moduleProvider.useModule("analytics", analyticsDatas, this.apiProvider, this.debugProvider);
    this.moduleProvider.useModule("browser-history", postMessageProvider);
    if (options.fixBlock?.active) this.moduleProvider.useModule("fix-block", options.fixBlock?.options || {});
    if (options.enableGeolocation) this.moduleProvider.useModule("geolocation", this.storageProvider);

    quiz.bind<ReachGoalData>(QuizEvent.REACH_GOAL, async (event) => {
      if (event.event == AnalyticsEvent.REACH_PHONE_FORM) {
        const sentryProvider = await this.moduleProvider.useModule("sentry", config);
        sentryProvider.init(quiz);
      }
    })

    // initialize modules
    quiz.bind(QuizEvent.BEFORE_INIT, () => {
      this.moduleProvider.modules.forEach(module => module.init(quiz));
    });

    window.dentoloUQuiz = quiz;
    return quiz;
  }

  public async applySettings(projectId: string, options?: IApplySettingsByProjectIdOptions) {
    this.debugProvider.log("projectId: ", projectId);
    const settings = await this.getSettings(projectId, options?.context);

    const quiz = quizFactory.create(settings.options);
    await quiz.init();

    return quiz;
  }

  private async getSettings(projectId: string, context: "top_window" | "current_window" = "current_window") {
    let options: IOptions;
    let setting: IClientSetting;
    let path = "";
    if (context == "top_window") path = (window.top?.location.hostname || "") + (window.top?.location.pathname || "");
    if (context == "current_window" || !path) path = window.location.hostname + window.location.pathname;

    if (!path.endsWith("/") && !path.endsWith(".html") && !path.endsWith(".php")) path = path + "/";

    const settings = await this.apiProvider.getSettings(projectId, path, this.VERSION);
    this.debugProvider.log("client settings: \n", settings);

    if (settings.length == 0) throw new Error("Settings are not found");
    if (settings.length == 1) return settings[0];

    setting = settings.sort((item1, item2) => item2.priority - item1.priority)
      .reduce((prev, cur) => supplementObjectSoft(prev, cur));

    options = settings.sort((item1, item2) => item2.priority - item1.priority)
      .map(setting => setting.options)
      .reduce((prev, cur) => supplementObjectSoft(prev, cur));

    if (options.mode == QuizMode.FULL) setting.fixBlock = false;
    setting.options = options;

    return setting;
  }
}