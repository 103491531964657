import { IStoreProvider } from "../interfaces/store-provider.interface";

export class StoreProvider<T> implements IStoreProvider<T> {
  constructor(private state: Readonly<T>) { }

  public getState(): Readonly<T> {
    return { ...this.state };
  }

  public updateState(data: Partial<T>) {
    this.state = { ...this.state, ...data };
  }
}
